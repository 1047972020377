@import '../ui-constants.scss';
@import '../ui-colors.scss';

.ui_rating-stars {
    height: 50px;

    span {
        font-size: $font-lg;
        font-weight: bold;
        color: $mint;
        margin-right: $spacing-sm;
    }

    .back-stars {
        display: flex;
        color: #e1e1e1;
        position: relative;
        text-shadow: 4px 4px 10px #e1e1e1;
    }
    .front-stars {
        display: flex;
        color: $mint;
        overflow: hidden;
        position: absolute;
        top: 0;
        transition: all .5s
    }
    
}
