@import '../../../../components/ui/ui-constants.scss';
@import '../../../../components/ui/ui-colors.scss';

.home-page-hero {
    width: 100%;
    position: relative;
    margin-bottom: 175px;
    display: flex;
    justify-content: center;

    &__hero {
        width: 100%;
        height: calc(100vh - 200px);
        min-height: 535px;
        max-height: 685px;
        background: url('/rosalind-chang-qzTrX18wBqo-unsplash.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.4);
            z-index: 1;
            content: '';
        }

        &__content {
            z-index: 2;
            border-radius: 4px;

            h2 {
                font-family: 'Comfortaa', cursive;
                color: $white;
                font-size: $font-xxxl;
                margin-bottom: $spacing-lg;
            }

            span {
                color: $blue-darker;
                font-weight: bold;
            }

            &__informations {
                color: $white;
                font-size: $font-md;
                margin: $spacing-sm;

                img {
                    width: 15px;
                    height: 15px;
                    margin-right: $spacing-xs;
                }
            }
        }
    }

    &__carousel {
        z-index: 2;
        position: absolute;
        width: calc(100% - 70px);
        max-width: 1200px;
        transform: translate(-50%);
        left: 50%;
        bottom: -75px;
        display: flex;

        &-item {
            height: 150px;
            width: 33.333333%;
            border: 3px solid #fff;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            cursor: pointer;
            position: relative;

            &:not(&--selected) {
                &::after {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(0, 0, 0, 0.6);
                    content: '';
                }
            }
        }
    }
}
