@import '../../components/ui/ui-constants.scss';
@import '../../components/ui/ui-colors.scss';

.top-places-page {
    margin-top: $spacing-xxl;

    h1 {
        font-family: 'Grand Hotel', cursive;
        font-size: $font-xxl + 12px;
        text-align: center;
    }

    &__place {
        margin-top: $spacing-lg;
        margin-bottom: $spacing-lg;

        h3 {
            font-family: 'Comfortaa', cursive;
            font-size: $font-lg;
            font-weight: bold;
        }

        &__img {
            width: 100%;
            height: 350px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
        }

        &__informations {
            padding-top: $spacing-lg;
            padding-bottom: $spacing-lg;
        }
        
        &__social-media {
            div {
                margin-right: $spacing-sm;
            }
            span {
                color: $blue-darker;
                font-weight: bold;
            }
            img {
                width: 15px;
                margin-right: $spacing-xs;
            }
        }
    }
}
