// Fonts
$font-xxs: 10px;
$font-xs: 12px;
$font-sm: 14px;
$font-md: 16px;
$font-lg: 20px;
$font-xl: 28px;
$font-xxl: 36px;
$font-xxxl: 42px;

// Spacing
$spacing-xs: 4px;
$spacing-sm: 8px;
$spacing-md: 16px;
$spacing-lg: 24px;
$spacing-xl: 32px;
$spacing-xxl: 48px;

// Border Radius
$border-radius: 4px;

// Mixins
$screen-sm-max: 768px;
$screen-md-max: 992px;
$screen-lg-max: 1200px; // laptop
$screen-xl-max: 2000px;
