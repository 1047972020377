@import '../../components/ui/ui-constants.scss';
@import '../../components/ui/ui-colors.scss';

.boba-tea-page {
    margin-top: $spacing-xxl;

    h3 {
        font-family: 'Grand Hotel', cursive;
        font-size: $font-xxl;
        text-align: center;
        margin-bottom: $spacing-xl;
    }


    &__carousel {
        &__item {
            height: 450px;
            width: 100%;
            background-repeat: no-repeat;
            background-size: cover;
        }
    }

    &__title {
        margin-top: $spacing-lg;

        h1 {
            font-family: 'Grand Hotel', cursive;
            text-align: center;
            font-size: $font-xxl + 12px;
        }
    }

    &__small-informations {
        margin-bottom: $spacing-lg;

        &__item {
            margin-right: $spacing-md;
        }

        img {
            width: 20px;
            margin-right: $spacing-sm;
        }
    }

    &__rating {
        margin-top: $spacing-lg;
    }

    &__informations {
        margin-top: $spacing-lg;
    }

    &__description {
        margin-top: $spacing-lg;
    }

    &__what-do-we-like {
        margin-top: $spacing-lg;

        &__img {
            height: 350px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
        }

        &__description {
            &__content {
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: $spacing-lg;
            }

            h3 {
                font-family: "Comfortaa", cursive;
                font-size: $font-lg;
                font-weight: bold;
                margin-bottom: $spacing-md;
            }
        }
    }

    &__access {
        margin-top: $spacing-lg;
    }

    &__feedback {
        margin-top: $spacing-lg;
    }
}