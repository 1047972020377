.ui_list {
    list-style: none;

    &__item {
    }

    /** LIST-STYLE */
    &__list-style {
        &--none {
            list-style: none;
        }
        &--disc {
            list-style: disc;
        }
        &--circle {
            list-style: circle;
        }
    }
}
