@import '../ui/ui-constants.scss';
@import '../ui/ui-colors.scss';

.menu-list {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: 0;

    &__item {
        padding: $spacing-md;
        font-family: 'Comfortaa', cursive;
        transition: 0.3s;
        list-style: none;

        &:hover {
            color: $blue-darker;
        }
    }
}
