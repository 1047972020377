@import '../../../../components/ui/ui-constants.scss';
@import '../../../../components/ui/ui-colors.scss';

$sidebar-width: 300px;

.home-page-latest-discoveries {
    width: 100%;
    padding-left: 35px;
    padding-right: 35px;
    margin-bottom: 100px;

    h2 {
        text-align: center;
        font-family: 'Grand Hotel', cursive;
        font-size: $font-xxl + 12px;
        margin-bottom: $spacing-lg;
        transition: 0.3s;

        &:hover {
            color: $blue-darker;
        }
    }

    // &__sidebar {
    //     width: $sidebar-width;
    //     padding-right: $spacing-md;

    //     h2 {
    //         font-family: 'Grand Hotel', cursive;
    //         font-size: $font-xxl;
    //         margin-bottom: $spacing-lg;
    //     }

    //     p {
    //         margin-bottom: $spacing-lg;
    //     }
    // }

    &__boba-tea-list {
        width: 100%;
        // width: calc(100% - #{$sidebar-width});
    }

    &__grid {
        margin-left: -40px;

        &__item {
            width: calc(50% - 40px);
            margin-left: 40px;
            margin-bottom: 40px;

            &__img {
                width: 100%;
                height: 0;
                padding-top: 55%;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
            }

            &__informations {
                &__title {
                    font-family: 'Comfortaa', cursive;
                    font-size: $font-xl;
                    margin-top: $spacing-sm;
                    margin-bottom: $spacing-sm;
                }
                &__categories {
                    margin-bottom: $spacing-sm;

                    div {
                        margin-right: $spacing-sm;
                    }

                    img {
                        width: 15px;
                        margin-right: $spacing-xs;
                    }
                }
                &__description {
                    margin-bottom: $spacing-sm;
                    font-size: $font-md;
                }
                &__actions {
                    margin-top: $spacing-md;
                }

                span {
                    color: $blue-darker;
                    font-weight: bold;
                }
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .home-page-latest-discoveries {
        &__grid {
            &__item {
                width: 100%;
            }
        }
    }
}
