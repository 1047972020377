@import '../ui/ui-constants.scss';
@import '../ui/ui-colors.scss';

.banner-join-us {
    background: #fafafa;
    width: 100%;
    margin-top: $spacing-xxl;
    padding: $spacing-xxl;

    &__cta {
        margin-top: $spacing-md;
    }

    h2 {
        color: $mint-darker;
        font-family: 'Comfortaa', cursive;
        font-size: $font-xl;
        font-weight: bold;
        margin-bottom: $spacing-md;
    }

    p {
        text-align: center;
    }
}