.home-page {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

@media only screen and (max-width: 600px) {
    .home-page {
        min-height: -webkit-fill-available;
    }
}
