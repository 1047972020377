@import '../node_modules/bootstrap/scss/bootstrap-grid.scss';
@import './components/ui/ui-constants.scss';
@import './components/ui/ui-colors.scss';

*,
*:after,
*:before {
    box-sizing: border-box;
    margin: 0;
}

a {
    color: inherit;
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

a:active,
a:visited {
    color: inherit;
}

/* clears the 'X' from Chrome */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
    display: none;
}

.App {
    background: #fafafa;

    width: 100%;
    height: 100%;

    font-family: 'Quicksand', sans-serif;
    font-size: $font-md;

    color: #333;
}

section {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}

@media only screen and (min-width: 1200px) {
    section {
        max-width: 1440px;
    }
}
