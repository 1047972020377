@import '../../components/ui/ui-constants.scss';
@import '../../components/ui/ui-colors.scss';

.about-us-page {
    margin-top: $spacing-xxl;

    h1 {
        font-family: 'Grand Hotel', cursive;
        font-size: $font-xxl + 12px;
        text-align: center;
    }
}
