.coming-soon {
    width: 100vw;
    min-height: 100vh;
    background: #f8f8f8;
    display: flex;
    align-items: center;
    justify-content: center;

    &__coming-soon {
        width: 75vw;
        height: 75vh;
        background: white;
        box-shadow: #ccc 3px 3px 3px 1px;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h1 {
            font-family: 'Grand Hotel', cursive;
            font-size: 8vw;
            margin-bottom: 0;
        }

        h2 {
            padding-top: 0;
            padding-right: 2em;
            padding-bottom: 2em;
            padding-left: 2em;
            font-family: 'Noto Sans', sans-serif;
            font-size: 3vw;
        }

        img {
            width: 125px;
        }
    }
}

@media only screen and (max-width: 600px) {
    .home-page {
        min-height: -webkit-fill-available;

        &__coming-soon {
            h1 {
                font-size: 10vw;
            }

            h2 {
                font-size: 5vw;
            }

            img {
                width: 85px;
            }
        }
    }
}
