@import '../ui/ui-constants.scss';
@import '../ui/ui-colors.scss';

.header {
    background: $white;
    box-shadow: 0 8px 30px -6px rgba(0, 0, 0, 0.05);

    &__container {
        height: 100px;
    }

    &__logo svg {
        width: 60px;
        height: 60px;
    }

    &__search-input {
        width: 125px;
        margin-left: $spacing-lg;
    }

    a {
        color: #333;
    }
    
    &--active {
        color: $blue-darker !important;
        font-weight: bold;
    }
}
