.ui_flex {
    display: flex;

    &--is-inline {
        display: inline-flex;
    }

    &--align-items {
        &-flex-start {
            align-items: flex-start;
        }
        &-center {
            align-items: center;
        }
        &-flex-end {
            align-items: flex-end;
        }
        &-stretch {
            align-items: stretch;
        }
        &-baseline {
            align-items: baseline;
        }
    }

    &--is-full-width {
        width: 100%;
        min-width: auto;
    }

    &--is-full-height {
        height: 100%;
        min-height: auto;
    }

    &--direction {
        &-row {
            flex-direction: row;
        }
        &-column {
            flex-direction: column;
        }
    }

    &--justify {
        &-flex-start {
            justify-content: flex-start;
        }
        &-center {
            justify-content: center;
        }
        &-flex-end {
            justify-content: flex-end;
        }
        &-space-between {
            justify-content: space-between;
        }
        &-space-around {
            justify-content: space-around;
        }
        &-space-evenly {
            justify-content: space-evenly;
        }
    }

    &--wrap {
        &-nowrap {
            flex-wrap: nowrap;
        }
        &-wrap {
            flex-wrap: wrap;
        }
        &-wrap-reverse {
            flex-wrap: wrap-reverse;
        }
    }
}
