@import '../ui-constants.scss';
@import '../ui-colors.scss';

.ui_button {
    border-radius: $border-radius;
    border: none;

    /** SIZE */
    &__sm {
        padding: $spacing-xs $spacing-sm;
    }
    &__md {
        padding: $spacing-sm $spacing-md;
    }
    &__lg {
        padding: $spacing-md $spacing-lg;
    }

    /** LEVEL */
    &__primary {
        border-radius: 0;
        border-bottom: 3px solid $blue-darker;
        font-family: 'Comfortaa', cursive;
        font-weight: bold;
        color: #333;
        transition: 0.6s;
        position: relative;

        &:hover {
            border-radius: $border-radius;
            color: $white;
            background: $blue-darker;
        }
    }
    &__secondary {
        background-color: $mint;
        color: $white;
        font-family: 'Comfortaa', cursive;
        font-weight: bold;
        text-transform: uppercase;
    }
}
