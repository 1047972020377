@import '../../../../components/ui/ui-constants.scss';
@import '../../../../components/ui/ui-colors.scss';

.home-page-top-boba-tea-places {
    width: 100%;
    padding-left: 35px;
    padding-right: 35px;
    margin-bottom: 100px;

    h2 {
        text-align: center;
        font-family: 'Grand Hotel', cursive;
        font-size: $font-xxl + 12px;
        margin-bottom: $spacing-lg;
        transition: 0.3s;

        &:hover {
            color: $blue-darker;
        }
    }

    &__grid {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        justify-content: flex-start;
        margin-left: -4px;

        &__item {
            position: relative;
            width: calc(50% - 4px);
            padding-top: 22.666%;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            margin-left: $spacing-xs;
            margin-top: $spacing-xs;

            &::after {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.4);
                z-index: 1;
                content: '';
                cursor: pointer;
            }

            &__content {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding: $spacing-md;
                color: $white;
                cursor: pointer;

                & > * {
                    z-index: 2;
                }

                &__title {
                    font-family: 'Comfortaa', cursive;
                    font-size: $font-lg;
                    margin-bottom: $spacing-md;
                }

                &__informations {
                    font-size: $font-md;

                    img {
                        width: 15px;
                        margin-right: $spacing-xs;
                    }

                    div {
                        margin-right: $spacing-sm;
                    }

                    span {
                        color: $blue-darker;
                        font-weight: bold;
                    }
                }
            }
        }

        &__item:nth-child(5n + 3),
        &__item:nth-child(5n + 4),
        &__item:nth-child(5n + 5) {
            width: calc(33.333333% - 4px);
            padding-top: 16.666%;
        }

        &__item:nth-child(1),
        &__item:nth-child(3) {
            border-left: none;
            // margin-left: -$spacing-xs;
        }

        &__item:nth-child(2),
        &__item:nth-child(5) {
            border-right: none;
        }
    }
}
