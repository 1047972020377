@import '../ui/ui-constants.scss';
@import '../ui/ui-colors.scss';

.footer {
    background: $white;
    padding: calc(#{$spacing-lg} * 2) $spacing-lg;

    &__item {
        &__title {
            font-family: 'Comfortaa', cursive;
            font-size: $font-lg;
            font-weight: bold;
            margin-bottom: $spacing-md;
        }

        .svg-inline--fa {
            margin-right: $spacing-sm;
        }
    }
}
